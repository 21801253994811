import React, { useEffect, useState } from 'react';
import styles from './ChallengePanelDashboard.module.css';
import { useValue } from '../../context/ContextProvider';
import { createChallenge, getGames } from '../../actions/challenges';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

const customStyles = {
  control: provided => ({
    ...provided,
    display: 'flex',
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

const Image = styled.img`
  margin-right: 10px;
  width: 30px;
  height: 30px;
`;

const formatOptionLabel = ({ label, image }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Image src={image} alt={label} />
    {label}
  </div>
);

const ChallengePanelDashboard = () => {
  const {
    state: { games, currentUser },
    dispatch,
  } = useValue();
  const MIN_CHARACTERS = 3;

  const fetchOptions = async inputValue => {
    try {
      if (inputValue.length < MIN_CHARACTERS) {
        return [];
      }
      const response = await getGames(dispatch, inputValue);

      return response.map(item => ({
        value: item.id,
        label: item.title,
        image: item.squareImage,
      }));
    } catch (error) {
      console.error('Error fetching the options:', error);
      return [];
    }
  };

  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [value3, setValue3] = useState();
  const [value4, setValue4] = useState();

  const handleChange = option => {
    setValue(option.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!value || !value2 || !value3 || !value4) return;
    createChallenge(
      currentUser,
      {
        game_id: value,
        multiplier: value2,
        min_bet: value3,
        reward: value4,
      },
      dispatch
    );
    setValue();
    setValue2();
    setValue3();
    setValue4();
  };

  return (
    <>
      <div className={styles.indexChallenges}>
        <div className={styles.challengesPanel}>
          <p className={styles.challengesPanelTitle}>Challenges Creator</p>
          <form onSubmit={handleSubmit}>
            <AsyncSelect
              loadOptions={fetchOptions}
              styles={customStyles}
              formatOptionLabel={formatOptionLabel}
              className={styles.accProfileInputStyle}
              onChange={handleChange}
            />
            {/* <label className={styles.accProfileInputLabel} for="reqmulti">REQUIRED MULTIPLER</label> */}
            <input
              onChange={e => setValue2(e.target.value)}
              className={styles.accProfileInputStyle}
              type="number"
              id="reqmulti"
              autoComplete="off"
              placeholder="REQUIRED MULTIPLER"
              name="requiredmultiplier"
              value={value2}
            />
            {/* <label className={styles.accProfileInputLabel} for="minbet">MIN BET</label> */}
            <input
              onChange={e => setValue3(e.target.value)}
              className={styles.accProfileInputStyle}
              type="number"
              id="minbet"
              autoComplete="off"
              placeholder="MIN BET"
              step=".1"
              name="minbet"
              value={value3}
            />
            {/* <label className={styles.accProfileInputLabel} for="reward">REWARD</label> */}
            <input
              onChange={e => setValue4(e.target.value)}
              className={styles.accProfileInputStyle}
              type="number"
              id="reward"
              autoComplete="off"
              placeholder="REWARD"
              name="reward"
              value={value4}
            />
            <button className={styles.accProfileButton} type="submit">
              CREATE CHALLENGE
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChallengePanelDashboard;
