// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  "type": "service_account",
  "project_id": "frankkastergg-a5720",
  "private_key_id": "d9276be39fd520c48725c97fdcb3f244895e943d",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCeEW8z3G4q6c0i\nOSkQ3dEQnFpZ1B/7igTKeiz4+CpAL/80iOtwJX68OedtbBb8KXeMi0OKQAwv93nq\nO2ypWDRCKKENDN6r3xaLZcQH16RJ2WbOUa8WQkMirdtWBpeBZ88RsWX/eeQva5bJ\nLl7v1M/78kYhq9+M4YIZ0/Ta9CMczXol5trARh61Qj2MqYInO+MoecFSFukpqW/J\nFgdrPQwGtxHHaOX2uPKNRGgE2ph5w2imZOmbgZX+B9IXQqK7wcrAMZ2jdrwxuVh2\n7DNX8lixLyAsdXzbo86CM7psh/oPEUxQqulAnZV2N3bUh/whJB0ODSuQ+akt12+x\nWmddHqq/AgMBAAECggEAO7Q2Ho6aBhd9ZfnVKwu9ldqYvelDk00Udi4jon6J5ui6\nKmu1Egz3i70LTudO3zwCbdGEV0nN0KhXIkQDzQsF4h9ahYVg7eY7q7dqjdPDuCkn\njbKIwY2Rsh2dBSdEPpqpOfOZpR+zMr4enCqprUjirjVajAlRTPM4ouzORY2FXbEf\nAnfv1/wkeGqQy21PYIiiGkM9n+IZcHKc/ahgJSwLiGkkntVt43XIKaOO6zRyK2qc\nEt76JInFT7NJouofAxLtN35kLtuoGSzkXa+GRp2EH6NC0alstdb3AVehLhP/3NTB\n7JU4xl/UmC9wUgx8UlkoFfjeNjcDXN9nK0+9e7AIsQKBgQDLslJ2ip02boZyKnk/\nexuwQ1G34kNwMFeCp56NzlIjMeN3GCLHWj+waszqN0GyUsxuQ/25f2iMKu1swc0n\nwfLFEQbgvLXgNvVLhKyRr/1UYhUiqF1Kz444NRqT3ewJT6bPIy560tuoLyAgeLRq\nSqh51g1i4WQN7kmT1M/tTGz9CwKBgQDGp8u3div+q7hzr3tVVJ0ZnFDYvHYdN1Ql\nLib6D/UZZD05blO+F7rutApRaItLtbGXEUV75jKK78jzcr/RVn4Ad7ftTv//B48F\n0z6+EuSgugAW/RHnYyUHaouGbaCdkheKTEBU6NF4iirgxzT8Oap16P+4UGLaep5N\nQwoA0wVRnQKBgQCmPAUoUL80aMa37Qm+UM2r74SFONYofIsuSXUP7U0DA/fKfZwE\nbRaE9uP8UNQAn6nOYxZxiBTeLzpMA21WAOYLBXZDej5DmRZeZwweaS2o69Kr0hMw\ntmouXMgv3HCAuMw9R0zrV6GDg9IILfmutPcBVzrdc2ugItNRHeACnNhlOQKBgGcZ\nnMvZg9nHi5JWx76wjFxHWVoy7WYaWgx12Hq9n2oPMQtGbCXjMPxD6nr3tBuV79ZE\ne3hjL2YWLofSzSS7K+fYOlfqQUg9xwex7AU/zvJvti44J3ucZBJmRIV/DfXZZWg8\nTMMElfpmUvyXxrMExqgtRdKFvdg//TqCCYYF/uOtAoGBAJBHUK+No/5d3xwY+KDQ\nK3LIjQjdVafGMVvttofcbdkNZihc9jAGZ0Qoo+AYD6TrgO+461FxAlys5+O2D/zs\n0bLYdh6Hhg6vTF3UzWEtu16k8qtd0TIZekGNeRIyvhvH4Zuka0W36OFcNCBTS6tz\nEhJRfx4fUmYwUU9Kzm4vUeFQ\n-----END PRIVATE KEY-----\n",
  "client_email": "firebase-adminsdk-z3nca@frankkastergg-a5720.iam.gserviceaccount.com",
  "client_id": "109115816463977095914",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-z3nca%40frankkastergg-a5720.iam.gserviceaccount.com",
  "universe_domain": "googleapis.com"
} 

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
