import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useValue } from '../../../context/ContextProvider';
import { getChallenges } from '../../../actions/challenges.js';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import ChallengesActions from './ChallengesActions.js';
import Protected from '../../../components/protected/Protected.js';

import ChallengePanelDashboard from '../../../components/challengePanelDashboard/ChallengePanelDashboard.jsx';

const Challenges = ({ setSelectedLink, link }) => {
  const {
    state: { challenges },
    dispatch,
  } = useValue();

  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  useEffect(() => {
    setSelectedLink(link);
    if (challenges.length === 0) getChallenges(dispatch);
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'slot_name',
        headerName: 'Slot',
        width: 250,
      },
      {
        field: 'photoURL',
        headerName: 'Image Link',
        width: 100,
        renderCell: params => <Avatar src={params.row.slot_image} />,
        sortable: false,
        filterable: false,
      },
      {
        field: 'multiplier',
        headerName: 'Multiplier',
        width: 150,
        editable: true,
      },
      {
        field: 'min_bet',
        headerName: 'Min Bet',
        width: 150,
        editable: true,
        renderCell: params => `${params.row.min_bet.$numberDecimal}`,
      },
      {
        field: 'reward',
        headerName: 'Reward',
        width: 150,
        editable: true,
      },
      {
        field: 'completed',
        headerName: 'Completed',
        width: 100,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'completed_by',
        headerName: 'Completed By',
        width: 200,
        editable: true,
      },
      {
        field: 'completed_at',
        headerName: 'Completed At',
        width: 200,
        editable: false,
        renderCell: params =>
          moment(params.row.completed_at).format('YYYY-MM-DD HH:MM:SS'),
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        renderCell: params =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        renderCell: params => (
          <ChallengesActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );

  return (
    <Protected>
      <ChallengePanelDashboard />
      <Box
        sx={{
          height: 400,
          width: '100%',
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Challenges Panel
        </Typography>
        <DataGrid
          columns={columns}
          rows={challenges}
          getRowId={row => row._id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          getRowSpacing={params => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: theme =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
          }}
          onCellEditCommit={params => setRowId(params.id)}
        />
      </Box>
    </Protected>
  );
};

export default Challenges;
