import fetchData from './utils/fetchData';

const url = 'https://api.keithlocks.tv/challenges';

export const getGames = async (dispatch, filter) => {
  const result = await fetchData(
    { url: url + '/games?filter=' + filter, method: 'GET' },
    dispatch
  );
  if (result) {
    dispatch({ type: 'UPDATE_GAMES', payload: result });
  }
  return result;
};

export const getChallenges = async dispatch => {
  const result = await fetchData({ url: url, method: 'GET' }, dispatch);
  if (result) {
    dispatch({ type: 'UPDATE_CHALLENGES', payload: result });
  }
};

export const createChallenge = async (currentUser, challenge, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    { url: url, body: challenge, token: currentUser.token },
    dispatch
  );
  if (result) {
    dispatch({ type: 'UPDATE_CHALLENGE', payload: result });
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'Challenge created successfully',
      },
    });
    return result;
  }

  dispatch({ type: 'END_LOADING' });
};

export const updateChallenge = async (currentUser, challenge, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    {
      url: url + '/' + challenge.id,
      method: 'PUT',
      body: challenge,
      token: currentUser.token,
    },
    dispatch
  );
  if (result) {
    dispatch({ type: 'UPDATE_CHALLENGE', payload: result });
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'Challenge updated successfully',
      },
    });
    return result;
  }

  dispatch({ type: 'END_LOADING' });
};
